<template>
  <TitleBar>Manage Mailings</TitleBar>
  <div class="py-2 px-1">
    <div
      class="grid mt-0 mx-3 surface-card shadow-2 p-3 border-1 border-50 border-round"
    >
      <DataTable
        class="col-12"
        :value="mailings"
        responsiveLayout="scroll"
        :lazy="false"
        :loading="loading"
        :paginator="true"
        :rows-per-page-options="rowSelectionOptions"
        v-model:rows="rowsToShow"
        :totalRecords="totalRecords"
      >
        <template #header>
          <div class="text-right flex flex-row-reverse"></div>
        </template>

        <Column
          field="campaign.name"
          header="Campaign"
          :sortable="true"
        ></Column>

        <Column field="name" header="Name" :sortable="true"></Column>

        <Column
          field="description"
          header="Description"
          :sortable="true"
        ></Column>

        <Column
          field="mailing_date"
          header="Mailing Date"
          :sortable="true"
        ></Column>

        <Column header="Edit">
          <template #body="slotProps">
            <Button
              label="EDIT"
              @click="
                selectedMailing = slotProps.data;
                toggleModal();
              "
            />
          </template>
        </Column>
      </DataTable>
    </div>
  </div>

  <EditMailing
    :visible="modalVisible"
    :mailing="selectedMailing"
    @updated="onUpdate"
  />
</template>

<script>
import Mailing from "@/models/Mailing";
import EditMailing from "@/components/modals/EditMailing";

import EventBus from "@/EventBus.js";
import TitleBar from "@/components/TitleBar.vue";
import { useAppStore } from "@/stores/app";

export default {
  name: "ManageMailings",
  components: { EditMailing, TitleBar },
  setup() {
    const appStore = useAppStore();
    return { appStore };
  },
  data() {
    return {
      mailings: [],
      loading: false,

      selectedMailing: null,
      modalVisible: false,

      totalRecords: 0,
      rowSelectionOptions: [10, 20, 50],
      rowsToShow: 10,
    };
  },
  mounted() {
    this.loadMailings();
  },
  methods: {
    loadMailings() {
      this.loading = true;

      Mailing.where("campaign_id", this.appStore.currentCampaign.id)
        .get()
        .then((response) => {
          this.mailings = response.data;
          this.totalRecords = response.meta.total;
        })
        .catch()
        .then(() => (this.loading = false));
    },
    toggleModal() {
      EventBus.emit("toggle-client-modal");
    },
    onUpdate(value) {
      console.log(value);
      this.loadMailings();
    },
  },
};
</script>
